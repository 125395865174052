@import 'partials/variables';


/*Bootstrap Imports*/
@import 'bootstrap/bootstrap.scss';
@import 'partials/nav';
@import 'partials/instafeed';
@import 'partials/footer';
@import 'partials/parallax';
@import 'partials/slick.scss';
@import 'partials/slick-theme.scss';

html, body{
    overflow-x: hidden;
}

h1,h2,h3,h4,h5,h6{
    font-family: $headingfont, serif !important;
    font-weight:$exbold;
}
body{
    font-family: $bodyfont, sans-serif !important;
    //background: #FFFFFF;
    background:url('../img/page-bg.png');

}
p{
    font-family: $bodyfont, sans-serif !important;
    @include fontSize(20px);
}
.admin-bar .main-nav {
  top: 32px;
}

img{
    width:100%;
    height:auto;

}
.bold{font-weight: $bold;}
.italic{font-style: italic;}

.fontblue{color:$brandblue;}
.fontpink{color:$pink;}
.fontyellow{color:$yellow;}
.fontgreen{color:$green;}
.blue-gradient{
    background-image: linear-gradient(180deg, lighten($darkestblue,10%) 0%, $darkestblue 64%);
}

.white{color:$white;}
.alignleft{
    float: left;
}
.hidden{
    opacity:0;
}
.show{
    opacity:1;
}
.center{
    text-align:center;
}
.full-width{
    width:100%;
}
.fadein{
    opacity: 1;
    transition: opacity .15s ease-in-out;
    -moz-transition: opacity .15s ease-in-out;
    -webkit-transition: opacity .15s ease-in-out;
}

.absolute-center {
  display: flex; // make us of Flexbox
  align-items: center; // does vertically center the desired content
  justify-content: center; // horizontally centers single line items
  text-align: center; // optional, but helps horizontally center text that breaks into multiple lines
}
.vertical-center{
    position: absolute;
  top: 50%;
  transform: translateY(-50%);}


.parallax {
  [class*="bg__"] {
    position : relative;
    height : 100vh;
    background-attachment : fixed;
    background-position   : center center;
    background-size       : cover;
    &:nth-child( 2n ) {
      //box-shadow : inset 0 0 1em #111;
    }
  }
  .bg__single{
      height:1000px !important;
  }
  .bg__featured{
     background-size: cover !important;
     background-repeat: no-repeat;
     height : 900px;
  }
}

.overlay{
    &:after{
        content:'';
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height:100%;
        z-index: 0;

    }
    &.black{
        &:after{
            background: rgba($black,.6);
        }
    }
}
#home, .main-content{
    margin-top:18%;
}
.sticky-donate-button{
    display: block;
    height:130px;
    width:58px;
    background: $darkerblue;
    border:1px solid $darkestblue;
    color:#FFFFFF;
    position: absolute;
    top: 100px;
    right:0px;
    border-top-left-radius:5px;
    border-bottom-left-radius:5px;
    z-index:10;
    p{
        transform: rotate(270deg);
        position: absolute;
        top: 95px;
        left: 11px;
        transform-origin: 0 0;
    }

}
span.green-highlight{
    color:#FFFFFF;
    background: rgba($darkergreen,.9);
    height:92px;
    padding:1%;
}
span.blue-highlight{
    color:#FFFFFF;
    background: rgba($darkerblue,.9);
    height:92px;
    padding:1%;
}
span.yellow-highlight{
    color:#FFFFFF;
    background: rgba($darkeryellow,.9);
    height:92px;
    padding:1%;
}
span.pink-highlight{
    color:#FFFFFF;
    background: rgba($darkerpink,.9);
    height:92px;
    padding:1%;
}
.btn, .btn.readmore{
    color:#FFFFFF;
    border-radius: 0px !important;
    z-index: 1;
    &.blue{
        background-color: $darkerblue; // abstract this and make into a mixin or createt specific classes that dictatte color
        border:1px solid darken($darkerblue,10%);
        &:hover{
            background: lighten($darkerblue,10%);
        }
    }
    &.pink{
        background-color: $darkerpink; // abstract this and make into a mixin or createt specific classes that dictatte color
        border:1px solid darken($darkerpink,10%);
        &:hover{
            background: lighten($darkerpink,10%);
        }
    }
    &.green{
        background-color: $darkergreen; // abstract this and make into a mixin or createt specific classes that dictatte color
        border:1px solid darken($darkergreen,10%);
        &:hover{
            background: lighten($darkergreen,10%);
        }
    }
    &.yellow{
        background-color: $darkeryellow; // abstract this and make into a mixin or createt specific classes that dictatte color
        border:1px solid darken($darkeryellow,10%);
        &:hover{
            background: lighten($darkeryellow,10%);
        }
    }
    &.gray{
        background-color: $gray2; // abstract this and make into a mixin or createt specific classes that dictatte color
        border:1px solid darken($gray2,10%);
        &:hover{
            background: lighten($gray2,10%);
        }
    }
}
//accordion
#accordion1, #accordion2{
.card .card-header:after {
    font-family: "FontAwesome" !important;
    content: "\f068";
    color:$darkestblue;
    float: right;
    font-size: 22px;
}
.card .card-header.collapsed:after {
    //font-family: "Font Awesome 5 Pro" !important;
    content: "\f067";
}
.card{border-radius: 0px !important;
background-color: #f3f3f3 !important;
border:0px !important;}
.card-header {
    padding: .95rem 1.25rem !important;
    background-color:#FFFFFF !important;
    border: 1px solid rgba(0,0,0,.125) !important;
    &:first-child{border-radius: 0px !important;}
}
.card-title{
    font-size:22px;
}
.card-body{
    li{
        padding:10px 0 0 0;
    }
}
}
//bottomcta
.smalltext{
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 38px;
    z-index:1;
    position: relative;
}
.big-number{
    font-weight: $exbold;
    font-size: 50px;
    color: #FFFFFF;
    letter-spacing: 0;
    position: relative;
    z-index: 1;
}
//Home
.herotext{
    h1{
        @include fontSize(65px);
        font-weight: $exbold;
        letter-spacing: 2.2px;
    }
}
.hero-video{
    //margin-top:-20px;
    //margin-left: -30px;
    //z-index:-10;
}
.hero-value-box{
     //background-image: linear-gradient(-89deg, rgba(#CACA00,.9) 0%, rgba(#32AB00,.9) 34%, rgba(#D409AC,.9) 65%, rgba(#008EC2,.9) 100%);
    // height:75%;
     p{
        font-size: 2em;
        text-align: center;
        font-weight: 300;}
}
.spotlight{
    & > h3{
        font-size: 30px !important;
        line-height: 45px;
    }
}
.highlight {
      span {
          //background-color: $darkerblue;
          display: inline;
          padding: 0.45rem;
          /* Needs refixing */
          box-decoration-break: clone;
          -webkit-box-decoration-break: clone;
          margin-left: -20%;
    }
    &.blue span{
        background-color: $darkerblue;
    }
    &.yellow span{
        background-color: $darkeryellow;
    }
    &.green span{
        background-color: $darkergreen;
    }
    &.pink span{
        background-color: $darkerpink;
    }

}
.flag{
    //height:112px;
    padding:4% 0 4% 2%;
    color:#FFFFFF;
    font-weight: $exbold;
    line-height: 50px;
    @include fontSize(57px);
    &.pink{
        background: $lighterpink;
        border:1px solid $darkerpink;
    }
    &.blue{
        background: $darkerblue;
        border:1px solid $darkestblue;
    }
    &.green{
        background: $darkergreen;
        border:1px solid $darkestgreen;
    }
    &.yellow{
        background: $darkeryellow;
        border:1px solid $darkestyellow;
    }
}
//cards
.eventcard{
    height:475px;
    > .title{
        font-size: 3.5vw;
        color: #FFFFFF;
        letter-spacing: -0.47px;
        text-align: center;
        font-weight: $exbold;
        position: relative;
        z-index: 1;
        text-transform: uppercase;

    }
    .date{
        border:1px solid $gray2;
        display: block;
        padding:2%;
        background: $gray3;
        color:#FFFFFF;
        font-weight: $bold;
        font-size: 18px;
        letter-spacing: -0.25px;
        z-index: 2;
        position: absolute;
        top:0;
        right:0;
    }
    &:after{
        content:'';
        display: block;
        position: absolute;
        width:100%;
        height:100%;
        mix-blend-mode: multiply;
    }
    &.blue-overlay{
        &:after{
            background-image: linear-gradient(226deg, rgba(#02D8F9,.5) 4%, rgba(#00465F,.5) 99%);
        }
    }
    &.pink-overlay{
        &:after{
            background-image: linear-gradient(226deg, rgba(#FA09CA,.5) 4%, rgba(#960679,.5) 99%);
        }
    }
    &.green-overlay{
        &:after{
            background-image: linear-gradient(226deg, rgba(#4BFB03,.5) 4%, rgba(#288A00,.5) 99%);
        }
    }
    a.card-link{
        position: absolute;
        display: block;
        width:100%;
        height:100%;
        z-index: 9;
        &:hover{
            border:1px solid $darkerpink;
        }
    }


}
.cta{
    position: relative;
    h2{
    font-size: 40px;
    padding:5% 5% 2% 5%;
    color: #FFFFFF;
    letter-spacing: 0;
    //text-align: center;
    line-height: 67px;
    font-weight: $exbold;
    position: relative;
    z-index: 1;}

}

.services{
    >div:first-child{
        border:none;
    }
    > div{
        border-left:4px solid rgba(#d8d8d8,.6);
    }
}

.bio-card{
    background: #FFFFFF;
    border:1px solid rgba(#979797,.1);
    p{margin-bottom: 0px;}
    &:hover{cursor:pointer;}
    .bio-pic2{
        //height:436px;
            @include object-fit(cover);
        }
}
.bio-pic{
    img{
        height:100%;
        @include object-fit(cover);
    }
}
.close{
    float:right;
}

.sponsors{
    p{
        font-size: 30px;
        color: #848484;
        letter-spacing: 1.15px;
    }
}
.sponsor-img{
    &:before{
        content: '';
        position: absolute;
        top: 25px;
        right: 25px;
        border-color: transparent;
        border-style: solid;
        border-width: 1.35em;
    }
    &.gold{
        &:before{
            border-right-color: #caaa00;
            border-top-color: #caaa00;
        }
    }
    &.silver{
        &:before{
            border-right-color: $gray5;
            border-top-color: $gray5;
        }
    }

}


//end home


//blog feed styling

h1.blog-heading{
    font-size: 14vw;
    -webkit-text-stroke-width: 2px;
    //-webkit-text-stroke-color: $dkblue;
    color:transparent;
    font-weight: $exbold;
    text-transform: uppercase;
}

.blogfeed{
    .post-meta{
        color:#000000;
    }
    h3 a{
        color:#000000;
    }
    .blog-text{
        //border-bottom: 3px solid $dkblue;
        h3{font-size: 2em;}
        p{@include fontSize(25px);}
    }
    a img{
        height:250px;
        @include object-fit(cover);
    }
    .featured-blog{
        a img{
            height:530px;
            @include object-fit(cover);
        }
    }
}

//single blog page styling
.single{
    .single-hero-title{
            @include fontSize(35px);
            text-align: center;
            text-transform: uppercase;
            color:#FFFFFF;
            padding:5% 2% 5% 2%;
            margin-top:45%;
            background: rgba(000,000,000,.4);
    }
    .single-paragraph p,.single-paragraph ul{
        @include fontSize(26px);
        line-height: 1.7em;
        letter-spacing: 0;
        line-height: 44px;
        font-weight: $font-light;
        strong{
            font-weight:$bold;
        }
        text-align:center;
    }
    .single-paragraph ul{
        li{list-style-type: none;
         list-style-position: inside;
         &:before{
            content:"\f0da";
            font-family: "Font Awesome 5 Pro" !important;
            margin-right:10px;
            font-weight:900;
         }
     }
    }

    h1{
        font-size: 6em;
        font-weight: $bold;
        text-transform: uppercase;
    }
    .single-paragraph{
            h1,h2,h3,h4,h5,h6{text-align:center;}
    }
    &.searchcontainer h1{
        font-size: 2.5em;
    }
    .searchform{
        width: 80% !important;
    }
    .pt-cv-content-item{
        background:#FFFFFF;
        border:1px solid #e2e2e2;
        margin-bottom: 3%;
        padding:0px !important;
    }
    .story-tag, .pt-cv-specialp{
        @include fontSize(17px);
        text-transform: uppercase;
        margin-left:0px !important;
        padding:12px;
        &.blue{
            background: $darkerblue;
        }
        &.pink{
            background: $darkerpink;
        }
        &.green{
            background: $darkergreen;
        }
        &.yellow{
            background: $darkeryellow;
        }

    }
    .pt-cv-wrapper .btn {
        //background-color: transparent;
        -webkit-appearance: none;
    }
    .story-tile-tag{
        float: right;
        position: absolute;
        right: 0;
        //right:16px;
        color:#FFFFFF;

    }
    a.tile-title , .tile-title h4 a{
        font-size: 1.1em;
        line-height: 1em;
        letter-spacing: 0;
        text-transform: uppercase;
        color: #4B4B4B !important;
    }
    .tile-excerpt,.pt-cv-content{
        font-weight: $light;
        font-size: 1.1em;
        color: #5D5D5D;
        letter-spacing: 0;
        line-height: 1.6em;
        margin-top: 3%;
    }
    span.caret {
        margin-left: 5px;
        margin-bottom: 0;
        display: none;
    }
    .pt-cv-wrapper .pt-cv-more{
        padding:6px 12px !important;
    }
    .pt-cv-social-buttons {
    margin-top: 0px !important;
    }
    a.btn{
        color:#FFFFFF !important;
    }
    .post-meta{
        span.author,span.post-date{
            color:#000000;
            @include fontSize(12px);
            padding: 12px;
            margin-left: 0% !important;
        }
        span.post-date{
            padding-left: 0px;
        }
        span.author{
            padding-right: 0px;
        }
    }
    .lead-in-txt{
        @include fontSize(30px);
    }
    .author{
        font-weight: $bold;
        text-transform: none;
        //text-decoration: underline;
    }
    .first-paragraph:first-letter{
        float:left;
        padding: 50px 15px 10px 0;
        font-weight: $exbold;
        @include fontSize(100px);
        //color:$dkblue;

    }
    .pullquote{
        @include fontSize(65px);
        //color:$dkblue;
        font-weight: $font-light !important;
    }

    &.blue{
        h3,h4,h5, strong, a{
            color:$darkerblue
        }
    }
    &.pink{
        h3,h4,h5, strong, a{
            color:$darkerpink
        }
    }
    &.green{
        h3,h4,h5, strong, a{
            color:$darkergreen
        }
    }
    &.yellow{
        h3,h4,h5, strong, a{
            color:$darkeryellow
        }
    }

}
//sidebar
#sidebar h3.widget-title{
    font-weight:$bold;
    font-size: 25px;
    color: #434343;
    }
.widget_recent_entries{
    background: #FFFFFF;
    border:1px solid rgba(#979797,.5);
    padding:10%;
    margin-bottom:4%;
    > ul{margin:0px;
    padding:0px;}
    li {
        a{
        font-size: 19.74px;
        color: #00465F;
        }
        padding: 15px 0 15px 0;
        border-bottom:1px solid rgba(#979797,.5);
        &:last-child{
            border-bottom:0px;
        }
    }
}
@media (min-width: 576px){
    .modal-dialog {
        max-width: 49% !important;
    }
}

@include media-breakpoint-down(md) {
    .herotext h1{
        font-size:4vw;
        line-height: 1.5em;
    }
    .hero-value-box p {
        line-height: 26px;
        font-size: 15px;
    }
    .flag {
        padding: 2% 0 2% 2%;
        font-size: 1.5625em;
    }
    //SERVICES
    .services{
        p{
            font-size: .8em;
        }
    }
    .bio-card p {
        font-size:.8em;
    }
    .sponsor-img:before {
        top: 5px;
        right: 4px;
    }
    //cards
    .eventcard{
        height:275px;}

    .featured h1{
        font-size:7.2vw;
    }
    .featured-box{
        h3{
            line-height: .9em !important;
            a{
                font-size:.7em;
            }
        }
        a img{
            height:125px;
            @include object-fit(cover);
        }
    }

    .post-meta {
        font-size: .8em;
    }
    .blogfeed {
        .featured-blog a img {
            height: 375px;
        }
        .blog-text h3 {
            font-size: 1.5em;
        }
        .blog-text p {
            font-size: 1.2em;
        }
        p.post-meta {
            font-size: 1em;
        }
        a img {
            height: 183px;
        }
    }

    .single {
        .single-hero-title {
            font-size: 7vh;
        }
        h1 {
            font-size: 7vw;
        }
        .image{
            //height:400px;
            img{
                height:350px;
                @include object-fit(cover);
            }
        }

        .pullquote {
            font-size: 3.5em;
            font-weight: $font-light !important;
        }
        .first-paragraph:first-letter {
            font-size: 5.25em !important;
        }
        .single .story-tag {
            font-size:1.5vh;
        }
    }

    .widget_recent_entries li a {
        font-size: 14.74px;
        //color: #00465f;
    }

    .bg__single{
        background-size: auto !important;
        background-repeat: no-repeat;
        height:inherit;
    }
    .bg__single{
        height:200px;
    }
    .parallax {

      .bg__featured{
         background-size: cover !important;
         background-attachment:inherit!important;
         background-repeat: no-repeat!important;
         background-position: 50% 50%!important;
         height : 600px!important;
      }
    }
}



h2.timeline-main-title, h1.timeline-main-title{
    display:none;
}
#story-409{
    .content-title{
        background: $darkerpink;
    }
}
#story-408{
    .content-title{
        background: $darkeryellow;
    }
}
#story-407{
    .content-title{
        background: $darkergreen;
    }
}
#story-406{
    .content-title{
        background: $darkerblue;
    }
}
.content-title-cmt{
    color:$gray3 !important;
}
@include media-breakpoint-down(sm) {
    #home, .main-content{
        margin-top:35% !important;
    }
    .spotlight>h3 {
        font-size: 20px!important;
        line-height: 30px;
    }
    .spotlight{
        .highlight span {
            margin-left: 0px;
        }
    }
    .single .story-tile-tag {

        right: 14px;}

        .sticky-donate-button {
    display: block;
    height: 104px;
    width: 41px;
    top: 50px;
    p{left: 10px;
        top: 79px;
        font-size: 1em;}
}
.xoxo{
    padding-left:0px;
}
.big-number {

    font-size: 18px;}
.smalltext{
    font-size: 13px;
}
.card-body{
    ul{
        padding-left: 0px;
    }
}
.page-template-resources .main-nav a.nav-link {
    color:#000000 !important;
}

.home .main-nav a.nav-link, .home .navbarscrolled a.nav-link{
    color:#000000 !important;
}
.eventcard>.title {
    font-size: 9.5vw;
}
.eventcard .date {
    right:30px;
}
.services{

    > div{
        border-left:0px;
    }
    text-align: center;
}
    .featured-box{
        margin-bottom:3%;
         a img {
            height: 217px;
        }
    }
    .featured{
        h1 {
           font-size: 12.2vw;
        }
        h1.lower {
            text-shadow: -6px 3px 0 #2b77e7 ;
        }
    }
    .featured-card {
        height: 397px;
        width: 71% !important;
        margin-top: 5%;
    }

    .single{
         .single-hero-title {
            font-size: 2em;
            margin-top:35%;
        }
        h1{
            font-size: 3em;
        }
        .lead-in-txt {
            font-size: 1.3em;
            padding:0px 1% 0px 1%;
        }
        p {
           font-size: 1.6em;
           line-height: 1.4em;
           padding: 0 5% 0 5%;
       }
       .first-paragraph:first-letter {
        float: left;
        padding: 46px 24px 25px 0;
        }

    }

    .social{
        padding:0%;
    }
    #footer{
        height:inherit;
    }

}
