#instafeed {
    //width: 100%;
    //margin: 0 auto;
    //text-align: center;
    /*@include bp($mobilel){
        padding: 20px;
    }*/
    .photo-all {
        //margin: 0.5%;
        position: relative;
        //border-bottom: 1px solid #c1c1c1;
        //padding-bottom: 32px;
        display: inline-block;
        //width: 32%;
        /*@include bp($larger) {
            width: 18%;
        }
        @include bp($large) {
            width: 23%;
        }
        @include bp($desktop) {
            width: 31%;
        }
        @include bp($tablet) {
            width: 46%;
             margin-bottom: 20px;
        }
        @include bp($mobilel) {
            width: 100%;
            margin-bottom: 30px;


        }*/
        .bgimg {
            padding-bottom: 100%;
            //border-radius: 10px;
        }

        .instalogo {
            margin: 0px 0px 0px 3%;
            position: absolute;
            bottom: 0px;
            left: 0px;
            display: none;

            img {
                width: 28px;
                margin-right: 5px;
                float: left;
                margin-bottom: 7px;
                border-radius: 50%;
            }
            a span {
                font: 12px/32px 'Open Sans', sans-serif;
                color: #000;
                overflow: hidden;
            }
        }
        .photo-caption {
            margin-top: -5px;
            overflow: hidden;
            //margin-top: 10px;
            width: 80%;
            padding-left: 15px;
            font: 14px/19px 'Open Sans', sans-serif;
        }
        .photo-location {
            float: left;
            width: 82%;
            height: 30px;
            overflow: hidden;
            a {
                font-size: 11px;
                line-height: 30px;
                color: #333;
                font-weight: normal;
            }
        }
        .photo-likes {
        display:none; 
       width: 38px;

            height: 30px;

            background: url("../img/heart.png") no-repeat scroll left center transparent;
            position: absolute;
            bottom: 0px;
            right: 0px;
            a {
                font-size: 11px;
                line-height: 30px;
                color: #555;
                font-weight: normal;
                padding-left: 24px;
            }
        }
    }
}


//lightbox class
body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
}

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;

  /* Image border */
  border: 4px solid white;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;

  /* Background color behind image.
     This is visible during transitions. */
  background-color: white;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../img/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  outline: none;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==');
}

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../img/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../img/next2.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../img/close2.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
