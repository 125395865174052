
.navbarscrolled, .navbarattop{
    transition: all .2s ease;
    img{transition: all .2s ease;}
}
.navbarattop{
    height:175px;
    .navbar-brand{
        img{width:100%;}
    }
}
.navbarscrolled{
    height:75px;
    border-bottom:1px solid #eaeaea;
    .navbar-brand {
        img{width:50% !important;}
    }
    .nav-link{
        font-size: 18px !important;
    }
}

.main-nav{
    padding: 3rem 1rem 3rem 1rem !important;
    font-family: $bodyfont;
    background: transparent;
    @include fontSize(25px);
    font-weight:$bold;

    a.nav-link{
        color:#000000 !important;
        //color:#FFFFFF !important;
        margin-left: .5em;
        font-weight: $bold;
        &:hover{
                color:$darkestblue !important;
        }
    }
    .current-page-ancestor a.nav-link {
            border-bottom: 8px solid $darkestblue;
            padding-bottom: 0.1rem;
    }
    .active > .nav-link{
        border-bottom:8px solid $darkestblue;
        padding-bottom:.1rem;
        color:#000000;
    }
    .dropdown-menu {
        background:rgba(000,000,000,.9);
        color:#FFFFFF;
        font-weight: bold;
        border-radius:0px;
        .dropdown-item {
            color:#FFFFFF;
            &:hover{
                background-color: lighten(#000000,20%);
            }
        }
    }
}

.navbar-toggler{
        background-color: #2f2f2f;
}

.home, .single {
    .main-nav{
        a.nav-link{
            color:#000000 !important;
        }
    }
    .navbarscrolled{
        a.nav-link{
            color:#000000;
        }
    }
}
.page-template-resources{
    .main-nav{
        a.nav-link{
            color:#FFFFFF !important;
        }
    }
    .navbarscrolled{
        a.nav-link{
            color:#000000 !important;
        }
    }
}

.page-id-240{
    .main-nav{
        a.nav-link{
            color:#000000 !important;
        }
    }
    .navbarscrolled{
        a.nav-link{
            color:#000000 !important;
        }
    }
}
@include media-breakpoint-down(md) {
    .navbar-nav{
        font-size: .6em !important;
    }
    .navbarscrolled{
        //height:75px;
        //border-bottom:1px solid #eaeaea;
        .navbar-brand {
            img{width:100% !important;}
        }
        .nav-link{font-size:.7em !important;}
    }
}
@include media-breakpoint-down(sm) {

    .menu-right-container{
        //padding-left:14.7%;
        display: block;
        width: 100%;
        background: #000000;
        .nav-item{
            &:nth-child(odd){
                background: lighten(#000000,10%);
            }
        }
    }
    .menu-left-menu-container{
        display: block;
        width: 100%;
        background: #000000;
        margin-top:-1px;
        .nav-item{
            &:nth-child(even){
                background: lighten(#000000,10%);
            }
        }
    }

    .main-nav {
        padding: 4rem 1rem!important;
        .navbar-brand, .navbar-toggler{
            margin-top:-40px;
        }
        .nav-item{
            transition: all .2s ease;
            &:hover{
                background: #CCCCCC;
            }
        }
        a.nav-link{
            margin-left: 0px;
            padding-left: 18%;
            &:hover{
                //background: #CCCCCC;
            }
        }
        .navbarscrolled{
            a.nav-link{
                color:#FFFFFF !important;
            }
        }
    }
    .home, .single {
        .main-nav{
            a.nav-link{
                //color:#FFFFFF !important;
                color:#000000 !important;
            }
        }
        .navbarscrolled{
            a.nav-link{
                //color:#FFFFFF !important;
                color:#000000 !important;
            }
        }
    }
    .navbar-toggler {
        background-color: $black !important;
    }
    .navbar-collapse{
        background: #FFFFFF;
    }
    .navbarscrolled{
        .nav-link{font-size:18px !important;}
    }
}
