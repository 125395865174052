.footer-widget-area{
    border-top:2px solid #003244;
}

.widget-title{
    font-weight: $bold;
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: 0;
}
.widget{
    p{
        font-weight: normal;
        color:#FFFFFF;
        font-size: 17px;
        color: #FFFFFF;
        letter-spacing: 0;
    }
    a{
        color:#FFFFFF;
        &:hover{
            color:#FFFFFF;
        }
    }
}


#footer{
    //margin-top: 100vh;
    border-top:1px solid $gray1;
    height:68px;
    width:100%;
    z-index: 100;
    position: relative;
    padding:2% 0 2% 0;
    bottom: 0px;
    right: 0px;
    left: 0px;

    #copyright{
        p{
            font-size:.7rem;
        }
    }
    #menu-footer-menu{
        list-style: none;
        li{
            display: inline-block;
            margin:0 0 0 1rem;
        }
    }
     .social-icon{
        display:inline-block;
        height:25px;
        width:25px;
        //background:#000000
    }
}

footer{
    background:$gray2;
    a{
        color:#FFFFFF;
        font-size: .8em;

        &:hover{
            color:$darkestpink;
        }
    }
    p{
        color:#FFFFFF;
        font-size: .8em;

    }

    .social-icon{
        color:rgba(255,255,255,.4);
        font-size: 1em;
    }
}
