/*Color Variables*/
$black: #000000;
$white: #FFFFFF;

$lighterpink:#FF75E3;
$pink: #FA09CA;
$darkerpink:#D409AC;
$darkestpink:#960679;

$green:#32AB00;
$darkergreen:#32AB00;
$darkestgreen:#288A00;

$brandblue: #01B0F0;
$darkerblue: #008EC2;
$darkestblue:#00465F;

$yellow:#CACA00;
$darkeryellow:#CACA00;
$darkestyellow:#8E8F00;


$gray1:#0C0C0C;
$gray2: #353535;
$gray3:#545454;
$gray4:#808080;
$gray5:#ACACAC;

/*fonts*/
$headingfont:'Lato';
$bodyfont:'Lato';
$exbold: 900;
$bold:700;
$normal: 400;
$font-light: 300;


@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}em;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}
@mixin object-fit($fit: cover, $position: null){
  -o-object-fit: $fit;
     object-fit: $fit;
  @if $position {
	  -o-object-position: $position;
	     object-position: $position;
  	font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
  	font-family: 'object-fit: #{$fit}';
  }
}

@mixin color-gradient($color1, $color2, $color3, $opacity){
background-image: -webkit-linear-gradient(45deg, $color1 10%, $color2 52%, $color3 91%);
background-image: -o-linear-gradient(45deg, $color1 10%, $color2 52%, $color3 91%);
background-image: linear-gradient(45deg, $color1 10%, $color2 52%, $color3 91%);
opacity:$opacity;
}


@mixin overlay($colorcode, $strength){
    content:'';
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    mix-blend-mode: multiply;
    z-index:10;
    @if $colorcode == 'black' {
        background-color: rgba(#000000, $strength);
    }
    @else{
        background-color:rgba(25, 25, 25, 0.66);
    }
}
